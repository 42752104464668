import React, {useState, useEffect, useContext} from "react";
import styled from "styled-components";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es"
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select'
import {DataContext} from "../../context/ContextData"
import { Input } from 'semantic-ui-react'
import moment from 'moment'
registerLocale("es", es)

const ModalBody = () => {

  const [selectedDate, setSelectedDate] = useState(null)
  const [turnos, setTurnos] = useState(null)
  const [horariosDisponibles, setHorariosDisponibles] = useState(null)

  function cargardatos () {
    fetch("https://adiosnonino.com/phpindex.php")
    .then(r => r.json())
    .then(r => setTurnosToday(r, barbero))
    .catch(e => console.log('failed'))
  }

  useEffect(() => {
    cargardatos()
  }, [])

  useEffect(() => {
    cargardatos()
  }, [selectedDate])

  function setTurnosToday (turnos, barbero) {
    let horariosParaFilter = []
    const fechaActual = moment(selectedDate).format('YYYY-MM-DD')
    const turnosFabri = turnos.filter(turno => (turno.barbero === barbero))
    const turnosFabriHoy = turnosFabri.filter(turno => turno.fecha === fechaActual)
    setTurnos(turnosFabriHoy)
    const horariosOcupados = turnosFabriHoy.length ? turnosFabriHoy.map(turno => turno.hora) : []
    if (servicio !== 'Corte y Afeitado') {
      horariosParaFilter = options.filter(hora => !horariosOcupados.includes(hora.value))
    } else {
      let disponibles = options.filter(hora => !horariosOcupados.includes(hora.value))
      if (selectedDate.getDay() == 2) {
        disponibles = disponibles.filter(date => !['10:00','10:30','11:00', '11:30','12:00','12:30','13:00', '13:30'].includes(date.value))
      }
      let disponiblesAmbos = []
      disponibles.forEach(hora => {
        const selectedIndex = options.indexOf(hora)
        const siguiente = options[selectedIndex + 1]
        if (disponibles.includes(siguiente)) {
          disponiblesAmbos.push(hora)
        }
      })
      horariosParaFilter = disponiblesAmbos
    }
    if (selectedDate.getDay() == 2) {
      const horarios = horariosParaFilter.filter(date => !['10:00','10:30','11:00', '11:30','12:00','12:30','13:00', '13:30'].includes(date.value))
      setHorariosDisponibles(horarios)
      return
    }
    setHorariosDisponibles(horariosParaFilter)
  }

  useEffect(() => {
    if (selectedDate) {
      setFecha(moment(selectedDate).format('YYYY-MM-DD'))
    }
  }, [selectedDate])

  const {fecha,
      setFecha,
      hora,
      setHora,
      nombre,
      setNombre,
      barbero,
      setBarbero,
      servicio,
      setServicio,
      celular,
      setCelular,
      email,
      setEmail,
      aclaracion,
      setAclaracion} = useContext(DataContext)

  const options = [
    {value: '10:00', label: '10:00'},
    {value: '10:30', label: '10:30'},
    {value: '11:00', label: '11:00'},
    {value: '11:30', label: '11:30'},
    {value: '12:00', label: '12:00'},
    {value: '12:30', label: '12:30'},
    {value: '13:00', label: '13:00'},
    {value: '13:30', label: '13:30'},
    {value: '14:00', label: '14:00'},
    {value: '14:30', label: '14:30'},
    {value: '15:00', label: '15:00'},
    {value: '15:30', label: '15:30'},
    {value: '16:00', label: '16:00'},
    {value: '16:30', label: '16:30'},
    {value: '17:00', label: '17:00'},
    {value: '17:30', label: '17:30'},
    {value: '18:00', label: '18:00'},
    {value: '18:30', label: '18:30'}
  ]

  const barberoptions = [
    {value: 'Fabricio', label: 'Fabricio'},
    {value: 'Santiago', label: 'Santiago'}
  ]

  return (
    <BodyContainer>
      <div className="columnas cold">
        <div><h2>Resumen</h2></div>
        <div>
          <h6>Servicio</h6>
          <span>{servicio ? servicio : '-'}</span>
        </div>
        <div>
          <h6>Barbero</h6>
          <span>{barbero ? barbero : '-'}</span>
        </div>
        <div>
          <h6>Fecha</h6>
          <span>{fecha ? fecha : '-'}</span>
        </div>
        <div>
          <h6>Horario</h6>
          <span>{hora ? hora : '-'}</span>
        </div>
        <div>
          <h6>Nombre</h6>
          <span>{nombre ? nombre : '-'}</span>
        </div>
        <div>
          <h6>Telefono</h6>
          <span>{celular ? celular : '-'}</span>
        </div>
        <div>
          <h6>Email</h6>
          <span>{email ? email : '-'}</span>
        </div>
        <div>
          <h6>Aclaracion</h6>
          <span className="text-break">{aclaracion ? aclaracion : '-'}</span>
        </div>
      </div>
      <div className="columnas">
        <div><h2></h2></div>
        <Select disabled={true} placeholder="Barbero" options={barberoptions} onChange={val => setBarbero(val.value)}/>
        <DatePicker
          locale="es"
          placeholderText="Fecha"
          className={"sarasa sarasapicker ui focus " + (!barbero ? "invisible" : "")}
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat='yyyy-MM-dd'
          filterDate={date => date.getDay() !== 0 && date.getDay() !== 1}
        />
        <Select className={"sarasa " + (barbero && fecha ? "" : "invisible")} placeholder="Horario" onChange={value => setHora(value.value)} options={horariosDisponibles} />
        <div><div class={"ui focus input sarasa " + (barbero && fecha && hora ? "" : "invisible")}><input placeholder="Nombre y Apellido" onChange={e => setNombre(e.target.value)} type="text"/></div></div>
        <div><div class={"ui focus input sarasa " + (barbero && fecha && hora && nombre ? "" : "invisible")}><input placeholder="Telefono" onChange={e => setCelular(e.target.value)} type="text"/></div></div>
        <div><div class={"ui focus input sarasa " + (barbero && fecha && hora && nombre && celular ? "" : "invisible")}><input placeholder="Email" onChange={e => setEmail(e.target.value)} type="email"/></div></div>
        <div><div class={"ui focus input sarasa " + (barbero && fecha && hora && nombre && celular && email ? "" : "invisible")}><input placeholder="Aclaracion" onChange={e => setAclaracion(e.target.value)} type="text"/></div></div>
      </div>
    </BodyContainer>
  );
};

export default ModalBody;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .columnas {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .columnas h6 {
    font-weight: bold;
    font-size: 1.3rem;
  }

  .columnas h2 {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .columnas span {
    font-size: 1rem;
  }

  .sarasapicker {
    margin: .5rem 0;
  }

  .sarasa {
    padding: .6rem;
    border-radius: .3rem;
    border-color: #D3D3D3;
    box-shadow: none;
    width: 100%;
    text-align: center;
  }

  .invisible {
    visibility:hidden;
  }

  .sarasa:focus {
    outline-color: #719ECE;
  }
`;
import React from "react";
import Inicio from "../components/Landing/Inicio/Inicio";
import Membresia from "../components/Landing/Membresia/Membresia";
import Navbar from "../components/Landing/Navbar/Navbar";
import Nosotros from "../components/Landing/Nosotros/Nosotros";
import Servicios from "../components/Landing/Servicios/Servicios";
import WhatsappIcon from "../components/Landing/WhatsappIcon/WhatsappIcon";
import Notifications, {notify} from 'react-notify-toast';

const Landing = ({ title1, title2 }) => {
  return (
    <div>
      <Navbar />
      <Inicio title1={"Contratá nuestra"} title2={"Membresía"} />
      <Servicios />
      <Nosotros />
      <Membresia />

      <div className="whatsapp">
        <a
          href="https://wa.me/5493413437234?text=Hola,%20necesito%20informacion"
          target="_blank"
        >
          <WhatsappIcon />
        </a>
      </div>
    </div>
  );
};

export default Landing;